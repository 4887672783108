import { Injectable } from '@angular/core';
import C2U from '@chemist2u/types-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SetupService {
    init() {
        this.initParse();
    }

    initParse() {
        C2U.initParse({
            APP_ID: environment.appId,
            SERVER_URL: environment.serverUrl,
            LIVEQUERY_SERVER_URL: environment.liveQueryServerURL,
        });
    }
}

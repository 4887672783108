import { Injectable } from '@angular/core';
import C2U from '@chemist2u/types-client';
import { CardEway, Redirect, User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import * as Parse from 'parse';
import { TAddUserDialogUser } from '../dialogs/add-customer/add-customer.page';
import { SortDirection } from '@angular/material/sort';
import { IUser, TStatus } from '@chemist2u/types-client/C2U/Interfaces';

@Injectable({
  providedIn: 'root'
})
export class CloudFunctionsService {

  constructor() { }

  public async markUserAsDeleted(id: string): Promise<boolean> {
    return Parse.Cloud.run('markUserAsDeleted', { id: id });
  }

  public async deactivateUser(id: string): Promise<User> {
    return Parse.Cloud.run('deactivateUser', { id: id });
  }


  public async activateUser(id: string): Promise<User> {
    return Parse.Cloud.run('activateUser', { id: id });
  }

  public async getHubspotAuthenticationToken(email: string, firstName: string, lastName: string): Promise<any> {
    return Parse.Cloud.run('getHubspotAuthenticationToken', { email: email, firstName: firstName, lastName: lastName });
  }

  public async loadCustomers(pagination: {
    page: number,
    limit: number
  } = { page: 0, limit: 15 }, sort: {
    direction: SortDirection,
    column: keyof User
  } = { direction: 'asc', column: 'createdAt' }, params: {
    status: TStatus,
  } | undefined, search: {
    column: keyof IUser,
    value: string
  } | undefined): Promise<User[]> {

    return await Parse.Cloud.run('getCustomers', {
      pagination: pagination,
      sort: sort,
      params: params,
      search: search
    })
  }

  async loadCustomerById(id: string): Promise<User> {
    let user = await Parse.Cloud.run('getCustomerByID', { id: id });
    return user as User;
  }

  async isUserType(username: string): Promise<Parse.Role> {
    return Parse.Cloud.run('isUserType', { username: username });
  }

  async loadAdminUsers(): Promise<User[]> {
    let users = await Parse.Cloud.run('getAdminUsers');
    return users as Promise<User[]>;
  }


  public async createAdminUser(user: any): Promise<User> {
    return Parse.Cloud.run('createAdminUser', user) as Promise<User>;
  }

  public async createCustomerUser(user: TAddUserDialogUser): Promise<User> {
    return Parse.Cloud.run('createCustomer', user) as Promise<User>;
  }

  public async editCustomerDetails(data: any): Promise<User> {
    return Parse.Cloud.run('updateUser', data) as Promise<User>;
  }

  public async loadRoute(orderId: string): Promise<Redirect> {
    return Parse.Cloud.run("getRouteObject", { orderId: orderId });
  }


  //Import Service Functions
  public async importPharmacyException(data: any) {
    return await Parse.Cloud.run('importPharmacyException', { exceptionJSON: data });
  }

  public async importOrderUpdates(data: any) {
    return await Parse.Cloud.run('importWeightLossOrders', { ordersJSON: data });
  }

  public async importOrderReminders(data: any) {
    return await Parse.Cloud.run('importOrderReminders', { remindersJSON: data });
  }


  //Card Functions
  async loadCardsForCustomer(id: string): Promise<CardEway[]> {
    let results = await Parse.Cloud.run('loadCardsForCustomer', { id: id });
    return results as Promise<CardEway[]>;

  }

  async createSquareCardToken(data: any): Promise<any> {
    return await Parse.Cloud.run('createSquareCardTokenNew', data);
  }

  //ABR Functions
  async checkABN(abn: string): Promise<C2U.Cloud.IBusiness> {
    return await C2U.Cloud.run("checkABN", { abn: abn });
  }

  async checkACN(acn: string): Promise<C2U.Cloud.IBusiness> {
    return await C2U.Cloud.run('checkACN', { acn: acn });
  }

  async checkBusinessName(searchString: string): Promise<C2U.Cloud.IBusinessSearch> {
    return await C2U.Cloud.run('checkBusinessName', { searchString: searchString });
  }

  getAddressList(search: string) {
    return C2U.Cloud.run('googleAutocomplete', { searchString: search });
  }


  getAddressDetails(place_id: string) {
    return C2U.Cloud.run('getPlaceDetails', { place_id: place_id });
  }

  getPostalCode(data) {
    let post_code = "";
    let address_components = data.address.address_components;
    address_components.forEach((val, i) => {
      let types = val.types;
      types.forEach((type, y) => {
        if (type == "postal_code") {
          post_code = val.short_name;
        }
      })
    })
    return post_code;
  }


  getAddressField(data: any, field: string): string {
    return (data.address_components.find(val => val.types.includes(field)) || { long_name: "" }).long_name;
  }

  getShortAddressField(data: any, field: string): string {
    return (data.address_components.find(val => val.types.includes(field)) || { short_name: "" }).short_name;
  }


  //Reports Service Functions

  async loadFullReport() {
    return await Parse.Cloud.run('fullTransactionReport');
  }

  async transactionReportByMonthYear(dateSelected: any, mode: "C2U" | "Marketplace" | "All" = "All") {
    return await Parse.Cloud.run('transactionReportByMonthYear', {
      start: dateSelected,
      mode
    });
  }

  async xeroTransactionReportByMonthYear(dateSelected: any) {
    return await Parse.Cloud.run('xeroModelReportByMonthYear', {
      start: dateSelected
    });
  }

  async generateScheduleItemsReport(data: any) {
    return Parse.Cloud.run('generateScheduleItemsReport', data);
  }

  async uberReportByMonthYear(date: any) {
    return Parse.Cloud.run('uberReportByMonthYear', { start: date })
  }

  async financeReportByDateRange(startDate: string, endDate: string, mode: "C2U" | "Marketplace" | "All" = "All") {
    return Parse.Cloud.run('financeReportByRange', { startDate, endDate, mode })
  }

  async polyPharmaOrdersReport(startDate: string, endDate: string) {
    return Parse.Cloud.run('polyPharmaOrdersReport', { startDate, endDate });
  }

  async paperScriptOrdersReport(date: any) {
    return Parse.Cloud.run('paperScriptOrdersReport', { date });
  }

  async firstTimeCustomerReportByRange(startDate: string, endDate: string) {
    return Parse.Cloud.run('firstTimeCustomerReportByRange', { startDate, endDate })
  }
  async ozempicCustomerReport(start: string, end: string) {
    return Parse.Cloud.run('ozempicCustomerReport', { start, end })
  }

  async wlpCustomerReport(start: string, end: string) {
    
    return Parse.Cloud.run("wlpCustomerReport",{start,end});
  }

  async publicHolidayExceptionsReport() {
    return Parse.Cloud.run("PublicHolidayReport")
  }
}

// Define a type for the most nested items, which are the actual menu links.
type NestedItem = {
  name: string;
  url: string;
};

// Define a type for the category, which includes a possible category name and an array of nested items.
type Category = {
  categoryName: string | null;
  nestedItems: NestedItem[];
};

// Define a type for the main menu items, which includes the name, URL, and an array of categories.
export type MenuItem = {
  name: string;
  url: string;
  categories: Category[];
};

export const SIDEBAR_MENU_ITEMS: MenuItem[] = [
  {
    name: 'Orders',
    url: '/orders',
    categories: [
      {
        categoryName: null,
        nestedItems: [
          {
            name: 'Active Orders',
            url: '/orders/active',
          },
          // {
          //   name: 'My Weight Loss Clinic',
          //   url: '/orders/weight-loss',
          // },
          // {
          //   name: 'Holding',
          //   url: '/orders/holding',
          // },
          {
            name: "Urgent",
            url: '/orders/status/urgent'
          },
          {
            name: 'Unpaid',
            url: '/orders/status/unpaid',
          },
          {
            name: 'Pending',
            url: '/orders/status/pending',
          },
          {
            name: 'Approved',
            url: '/orders/status/approved',
          },
          {
            name: 'Paid',
            url: '/orders/status/paid',
          },
          {
            name: 'Pharmacy Approved',
            url: '/orders/status/pharmacy-approved',
          },
          {
            name: 'Ready',
            url: '/orders/status/ready',
          },
          {
            name: 'Delivery Booked',
            url: '/orders/status/delivery-booked',
          },
          {
            name: 'Shipped Out',
            url: '/orders/status/shipped-out',
          }
        ]
      },
      {
        categoryName: null,
        nestedItems: [
          // {
          //   name: 'C2U Managed',
          //   url: '/orders/c2u-managed'
          // },
          // {
          //   name: 'Self Managed',
          //   url: '/orders/self-managed'
          // },
          {
            name: 'Historical Orders',
            url: '/orders/historical'
          },
          {
            name: "Program",
            url: '/orders/status/Program'
          },
          // {
          //   name: "Postal",
          //   url: '/orders/status/postal'
          // },
          // {
          //   name: "Mounjaro",
          //   url: '/orders/status/mounjaro'
          // },
          {
            name: 'Hub Orders',
            url: '/orders/status/hub-orders'
          },
          // {
          //   name: 'Holding',
          //   url: '/orders/holding',
          // },
          {
            name:'Inbox',
            url: '/orders/inbox'
          },
        ]
      },
      {
        categoryName:'Market Place',
        nestedItems: [
          {
            name: 'Active Orders',
            url: '/orders/market-place',
            
          },
          {
            name:'Archive',
            url: '/orders/market-place-historical'
          }
        ]
      }
    ]
  },
  {
    name: 'Catalog',
    url: '/catalog',
    categories: [
      {
        categoryName: 'Items',
        nestedItems: [
          {
            name: 'Products',
            url: 'catalog/products',
          },
        ]
      },
      {
        categoryName: 'Brands',
        nestedItems: [
          {
            name: 'Brands',
            url: '/catalog/brands',
          }
        ]
      },
      {
        categoryName: 'Categories',
        nestedItems: [
          {
            name: 'Categories',
            url: '/catalog/categories',
          },
          {
            name: 'Sub-categories',
            url: '/catalog/subcategories',
          }, {
            name: 'Tags',
            url: '/catalog/tags',
          }
        ]
      }
    ]
  },
  {
    name: 'Pharmacy',
    url: '/pharmacy',
    categories: [
      {
        categoryName: 'MENUS',
        nestedItems: [
          {
            name: 'Pharmacies',
            url: '/pharmacy/pharmacies',
          },
        ]
      }
    ]
  },
  {
    name: 'Customer',
    url: '/customer',
    categories: [
      {
        categoryName: 'Customers',
        nestedItems: [
          {
            name: 'Customers',
            url: '/customer/current'
          }
        ]
      }
    ]
  },
  {
    name:'Promotion',
    url: '/promotion',
    categories:[
      {
        categoryName: 'Promotions',
        nestedItems:[
          {
            name:'Promotions',
            url:'/promotion/current'
          }
        ]
      }
    ]
  },
  {
    name: 'Feedback',
    url: '/feedback',
    categories: [
      {
        categoryName: 'Feedback',
        nestedItems: [
          {
            name: 'Feedback',
            url: '/feedback/current'
          }
        ]
      }
    ]
  },
  {
    name: 'Settings',
    url: '/settings',
    categories: [
      {
        categoryName: 'Users',
        nestedItems: [
          {
            name: 'Admin',
            url: '/settings/users'
          },
          {
            name: 'Partner',
            url: '/settings/partner'
          },
        ]
      },
      {
        categoryName: 'General',
        nestedItems: [
          {
            name: 'Serviceability',
            url: '/settings/serviceability'
          },
          {
            name: 'Reports',
            url:'/settings/report'
          },
          {
            name: 'Imports',
            url:'/settings/import'
          },
          {
            name: "Manage NONPBS",
            url: '/settings/non-pbs'
          },
          {
            name: 'Carousel Images',
            url:'/settings/carousel'
          },
          {
            name: 'Admin Reports',
            url: '/settings/admin-reports'
          },
          {
            name: 'Create Custom SMS',
            url: '/settings/create-custom-sms'
          },
          {
            name: 'Platform SMS',
            url: '/settings/automated-sms'
          },
          {
            name: 'App Announcement',
            url: '/settings/app-announcement'
          },
          {
            name: 'Public Holidays',
            url: '/settings/public-holidays'
          },
          {
            name: "Cohort Codes",
            url: "/settings/cohort-codes",
          },
          {
            name: 'Auto Message Configuration',
            url: '/settings/auto-message-configuration'
          },
          {
            name: "Hub Pharmacies",
            url: '/settings/hub-pharmacies'
          },
          {
            name: 'Pharmacy Comment Config',
            url: '/settings/pharmacy-comment-config'
          },
          {
            name: "Tags",
            url: '/settings/misc-strings'
          }
        ]
      }, 
    ]

  }
];

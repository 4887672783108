<div class="pane" >
    <div class="header">
        <div class="title"><h3>Delivery Method</h3> <span>New</span></div>
        <div class="message">
            <mat-icon class="icon">info_outline</mat-icon>
            <p class="">Please note, we've introduced new delivery methods.</p>
            <mat-icon class="icon close">close</mat-icon>
        </div>
    </div>
    <div class="content" [ngStyle]="{'background-color': deliveryMethod === 'SameDay' ? '#a2ddff':''}">
        <div class="icon">
            <img width="24px" height="24px" [src]="deliveryIcon" alt="">
        </div>
        <div class="steps">
            <h2 class="method">{{deliveryMethodText}}</h2>
            <h4 class="desc">Follow these steps</h4>
            <ol>
                <li *ngFor="let step of steps">{{step}}</li>
            </ol>
        </div>
        <div class="pin" *ngIf="deliveryMethod === 'clickAndCollect'" >
            <h5>Order Collection Pin</h5>
            <p>{{pin? pin : "----"}}</p>
        </div>
    </div>
</div>
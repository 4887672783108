<div class="topbar-wrapper" *ngIf="showTopbar()">
    <div class="topbar">
        <div>
            <div>
                {{ isExperimental() ? "Switch to the old version of the dashboard." : "New experimental version of the dashboard is available." }}
            </div>
            <div class="warn" *ngIf="isExperimental()">
                <b>Warning:</b> The new version is still experimental.
            </div>
        </div>
        <div class="action-wrapper">
            <button (click)="toggleVersion()">
                {{ isExperimental() ? 'Go to Old' : 'Go to Beta' }}
            </button>
            <div class="close-btn" (click)="dismissTopbar()">&times;</div>
        </div>
    </div>
</div>
// session-extension.service.ts
import { Injectable, OnDestroy } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
    providedIn: 'root'
})
export class SessionExtensionService implements OnDestroy {
    private readonly storageKey = 'lastSessionExtension';
    private readonly checkInterval = 2 * 60 * 1000; // Check every 2 minutes
    private readonly extensionInterval = 9 * 60 * 1000; // Extend session every 5 minutes
    private intervalId: number | null = null;

    constructor() {
        this.initialize();
    }

    /**
     * Initialize the session extension monitoring
     */
    public initialize(): void {
        this.stop(); // Clear any existing interval first

        // Start checking for session extension needs
        this.intervalId = window.setInterval(() => {
            void this.checkAndExtendSession();
        }, this.checkInterval);

        // Also check immediately on initialization
        void this.checkAndExtendSession();
    }

    /**
     * Check if session needs extension and extend it if necessary
     */
    private async checkAndExtendSession(): Promise<void> {
        // Verify user is logged in
        const currentUser = Parse.User.current();
        if (!currentUser) return;

        // Get timestamp of last extension from localStorage
        const lastExtensionStr = localStorage.getItem(this.storageKey);
        const lastExtension = lastExtensionStr ? parseInt(lastExtensionStr, 10) : 0;
        const now = Date.now();

        // If extension is needed
        if (now - lastExtension > this.extensionInterval) {
            try {
                // Call the simple extension function
                await Parse.Cloud.run('extendSession');

                // Update localStorage with current timestamp
                localStorage.setItem(this.storageKey, now.toString());
                console.log('Session extended at', new Date(now).toLocaleTimeString());
            } catch (error) {
                console.error('Failed to extend session:', error);
            }
        }
    }

    /**
     * Stop the session extension monitoring
     */
    public stop(): void {
        if (this.intervalId !== null) {
            window.clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    /**
     * Clean up on service destruction
     */
    ngOnDestroy(): void {
        this.stop();
    }
}
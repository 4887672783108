import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService {
  private hostname = environment.isDev ? '.local.test' : '.chemist2u.com.au';

  private newInstallationId = `Parse/${environment.appId}/currentInstallationId`;
  private oldInstallationId = `Parse/${environment.appId}/installationId`;
  private currentUser = `Parse/${environment.appId}/currentUser`;

  /**
   * Loads cookies into localStorage on startup
   */
  public loadCookiesIntoLocalStorage() {
    const cookieValue = this.getCookie(this.newInstallationId);
    if (cookieValue) {
      localStorage.setItem(this.oldInstallationId, cookieValue);
    }
    this.loadCookieIntoLocalStorage(this.currentUser);
  }

  /**
   * Loads cookie value into localStorage on startup
   */
  private loadCookieIntoLocalStorage(key: string): void {
    const cookieValue = this.getCookie(key);
    if (cookieValue) {
      localStorage.setItem(key, cookieValue);
    }
  }

  /**
   * Saves value from localStorage back to a domain cookie
   */
  public saveLocalStorageIntoCookies(): void {
    const storedValue = localStorage.getItem(this.oldInstallationId);
    if (storedValue) {
      this.setCookie(this.newInstallationId, storedValue, 7); // Expires in 7 days
    }
    this.saveToCookie(this.currentUser);
  }

  /**
   * Expires all domain cookies
   */
  public clearCookies(): void {
    this.clearCookie(this.newInstallationId);
    this.clearCookie(this.currentUser);
  }

  /**
   * Saves value from localStorage back to a domain cookie
   */
  private saveToCookie(key: string): void {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      this.setCookie(key, storedValue, 7); // Expires in 7 days
    }
  }

  /**
   * Retrieves a cookie value by name
   */
  private getCookie(name: string): string | null {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`)
    );
    return matches ? decodeURIComponent(matches[1]) : null;
  }

  /**
   * Sets a cookie with a given name, value, and expiration (in days)
   */
  private setCookie(name: string, value: string, days: number): void {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    const isSecure = environment.isDev ? 'SameSite=Lax;' : 'SameSite=None; Secure;'
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires.toUTCString()}; path=/; domain=${this.hostname}; ${isSecure}`;
  }

  /**
   * Clears a cookie and its corresponding localStorage entry
   */
  private clearCookie(name: string): void {
    // Expire the cookie by setting a past date
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.hostname}`;
  }
}

import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CookieStorageService } from 'src/app/services/cookie-storage.service';
import { StateService } from 'src/app/services/state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-environment-topbar',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './environment-topbar.component.html',
  styleUrls: ['./environment-topbar.component.scss']
})
export class EnvironmentTopbarComponent implements OnInit {
  public $auth = inject(AuthService);
  public $state = inject(StateService);
  public $cookie = inject(CookieStorageService);

  public showTopbar = signal<boolean>(false);
  public isExperimental = signal<boolean>(false);

  ngOnInit() {
    this.$state.isLoggedIn.subscribe(state => {
      if (state) {
        const userRole = this.$auth.getRole();
        if (userRole === 'Admin') {
          this.showTopbar.set(true);
          this.$cookie.saveLocalStorageIntoCookies();
        }
      } else {
        this.showTopbar.set(false);
        this.$cookie.clearCookies();
      }
    });
  }

  public dismissTopbar() {
    this.showTopbar.set(false);
  }

  public toggleVersion() {
    const betaUrl =
    environment.isProd ? 'https://beta-admin.chemist2u.com.au' :
    environment.isStag ? 'https://beta-admin-staging.chemist2u.com.au' :
    'http://beta-admin.local.test:4202';
    const oldUrl =
    environment.isProd ? 'https://admin.chemist2u.com.au' :
    environment.isStag ? 'https://admin-staging.chemist2u.com.au' :
    'http://admin.local.test:4201';

    if (this.isExperimental()) {
      window.location.href = oldUrl;
    } else {
      window.location.href = betaUrl;
    }
  }
}

import { Component, Injector, Inject } from '@angular/core';
import { BasePage } from '../../pages/base-page';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'create-pharmacy-compliance',
  templateUrl: './create-pharmacy-compliance.component.html',
  styleUrls: ['./create-pharmacy-compliance.component.scss']
})
export class CreatePharmacyComplianceComponent  extends BasePage {
  constructor(injector: Injector,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreatePharmacyComplianceComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any  | undefined) {
    super(injector);
  }
  public form: FormGroup;
  showSubtypeDropdown = false;
  subtypeOptions: string[] = [];
  values: string[] = [];
  typeMapping = {
    'Pharmacy Incidents': 'pharmacyIncidents',
    'Logistics Incidents': 'logisticsIncidents'
  };
  dataMapping = {
    pharmacyIncidents: {
      'Order Fulfillment Issues': [
        'Missing Needles (e.g., Mounjaro)',
        'Order Prepared but Out-of-Stock Not Flagged',
        'Incorrect or Partial Order Prepared',
        'Failure to Alert Team to Out-of-Stock Items'
      ],
      'Communication Issues': [
        'Multiple Unanswered Calls',
        'Pharmacy Not Responding to Messages',
        'Pharmacy Ignoring Comments/Instructions',
        'Pharmacy Not Informing of Medication Delays or Out-of-Stock',
        'Customer Contacted Directly Without Team Being Informed'
      ],
      'Delivery Issues': [
        'No Notification of Order Ready for Pickup',
        'Order Marked as Ready but Incomplete',
        'Self-Delivery by Pharmacy Not Completed',
        'Delayed Delivery Due to Out-of-Stock',
        'Incorrect dosage dispensed',
        'Delay in dispatch'
      ],
      'Customer Service Issues': [
        'Placed on Hold Without Resolution',
        'Calls Dropped or Disconnected',
        'Unhelpful or Unresponsive Pharmacy',
        'Pharmacy Refused to Check Stock Due to Workload'
      ],
      'Product Issues': [
        'Missing Medications or Items',
        'Incorrect Dispensing of Medication',
        'Faulty product'
      ],
      'Pricing Issues': [
        'Incorrect display of price on dispense label/receipt',
        'Pharmacy awaiting to increase price',
      ],
      'Others': []
    },
    logisticsIncidents: {
      'Customer Service Problems': [
        'Poor Response Times',
        'Rudeness or Lack of Support',
        'Multiple Attempts to Resolve Without Success',
        'Pharmacy Refused to Assist',
        'Customer reported receiving shipment in a warm condition'
      ],
      'Delivery Issues': [
        'Delayed or Missed Deliveries',
        'Incorrect or Partial Orders Marked as Complete',
        'Failure to Notify Team of Order Changes'
      ],
      'Others': []
    }
  };
  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [],
      type: ['', Validators.required],
      subtype: ['', Validators.required],
      value: ['', Validators.required],
    });
    this.form.get('type')?.valueChanges.subscribe((type) => {
      this.onTypeChange(type);
    });
  }

  savePharmacyTemplate() {
    if(this.form.valid) {
      const formData = this.form.getRawValue();
      this.dialogRef.close(formData);
    } else {
      alert("Please fill all the fields");
    }
  }

  onTypeChange(type: string): void {
    this.subtypeOptions = Object.keys(this.dataMapping[type] || {});
    this.form.patchValue({ subtype: null, value: null });
    this.values = [];
    this.showSubtypeDropdown = false;
  }

  onSubTypeChange(subtype: string): void {
    const type = this.form.get('type')?.value;
    this.form.patchValue({ value: null });
    this.values = this.dataMapping[type]?.[subtype] || [];
    this.showSubtypeDropdown = this.values.length > 0;
    if (!this.showSubtypeDropdown) {
      this.form.patchValue({ value: '' });
    }
  }

}
import { Component, Input, SimpleChanges} from '@angular/core';
import { TFulfillmentMethodName } from '@chemist2u/types-client/C2U/Interfaces';


@Component({
  selector: 'c2u-delivery-method',
  templateUrl: './c2u-delivery-method.component.html',
  styleUrls: ['./c2u-delivery-method.component.scss']
})

export class C2uDeliveryMethodComponent {
 @Input() deliveryMethod: TFulfillmentMethodName;
 @Input() pin:string = undefined;

 public deliveryMethodText: string = '';
 public steps: string[] = [];
 public deliveryIcon: string = '';

 public clickAndCollect =[
  "When order is ready for collection, click Approve then click Ready",
  "Check the customer’s collection PIN matches the Order Collection PIN",
  "Optionally, check their ID",
  "Request customer checks their order in front of you, and clicks 'Collected' in their app"
 ];
 public Standard =[
  "When order is ready for delivery, click Approve then click Ready",
  "A courier will be dispatched"
 ];
 public Postal = [
  "When order is ready for delivery, click Approve then click Ready",
  "Click on Generate Label",
  "Print the label and attach it to the parcel straight away",
  "Drop the parcel off at the post office"
 ];
 public PostalTemperatureControlled = [
  "When order is ready for delivery, click Approve then click Ready",
  "Click on Generate Label",
  "Print the label and attach it to the parcel straight away",
  "Drop the parcel off at the post office"
 ]
 public SameDay = [
  "When order is ready to ship, click Approve then click Ready",
  "Click on Generate Label",
  "Print the label and attach to the parcel",
  "Stage parcel ready for collection by StarTrack today"
]
 public OnDemand = [
  "When order is ready for delivery, click Approve then click Ready",
  "A courier will be dispatched"
 ];

 ngOnChanges(changes: SimpleChanges): void {
  if (changes['deliveryMethod'] && this.deliveryMethod) {
    this.updateDeliveryMethod();
  }
}

 updateDeliveryMethod(): void {
  console.log(this.deliveryMethod);
  
  if(this.deliveryMethod === 'clickAndCollect'){
    this.deliveryMethodText = 'Click & Collect';
    this.steps = this.clickAndCollect;
    this.deliveryIcon = './assets/icons/click-and-collect.svg';
  }
  else if (this.deliveryMethod === 'Standard'){
    this.deliveryMethodText = 'Standard';
    this.steps = this.Standard;
    this.deliveryIcon = './assets/icons/standard-delivery.svg';
  }
  else if (this.deliveryMethod === 'Postal'){
    this.deliveryMethodText = 'Postal';
    this.steps = this.Postal;
    this.deliveryIcon = './assets/icons/postal-delivery.svg'
  }
  else if (this.deliveryMethod === 'PostalTemperatureControlled'){
    this.deliveryMethodText = 'Temperature Controlled';
    this.steps = this.PostalTemperatureControlled;
    this.deliveryIcon = './assets/icons/postal-delivery.svg';
  }
  else if (this.deliveryMethod === 'SameDay'){
    this.deliveryMethodText = 'Same Day';
    this.steps = this.SameDay;
    this.deliveryIcon = './assets/icons/startrack.png';
  }
  else {
    this.deliveryMethodText = 'On Demand';
    this.steps = this.OnDemand;
    this.deliveryIcon = './assets/icons/standard-delivery.svg';
  }
 }

}

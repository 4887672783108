import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';

@Injectable({
  providedIn: 'root'
})

export class AdminGuard  {

  constructor(private router: Router) {}

  canActivate() {
    const currentUser = User.current();
    const accessList = ['admin','p.trescowthick','alex','magnacarta','mattsiddons','s.wilson','chloe','thanh','khizra','Renae', 'ryan', 'm.fuller','p.trescowthick','nick.b','anna.s'];
    if (currentUser !== null && currentUser.authenticated() && accessList.includes(currentUser.getUsername())) {
      return true;
    }
    this.router.navigate(['/'], );
    return false;
  }
}

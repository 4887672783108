import { Injectable } from '@angular/core';
import { User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';

@Injectable({
  providedIn: 'root'
})
export class SocketAuthService {
  private readonly AUTH_STORAGE_KEY = 'socket_auth_data';
  
  /**
   * Prepare authentication data for socket connection
   * Using the existing Parse session token for security
   */
  prepareAuthData(): any {
    const currentUser = User.current();
    if (!currentUser) {
      console.warn('No current user found for socket authentication');
      return { authenticated: false };
    }
    
    try {
      // Get user information
      const username = currentUser.get('username') || currentUser.getUsername();
      const userId = currentUser.id;
      // Use existing Parse session token - this is the key to our security approach
      const sessionToken = currentUser.getSessionToken();
      
      if (!sessionToken) {
        console.warn('No session token available for socket authentication');
        return { authenticated: false };
      }
      
      const authData = {
        username,
        userId,
        sessionToken,
        timestamp: Date.now()
      };
      
      // Store this data for reconnection
      localStorage.setItem(this.AUTH_STORAGE_KEY, JSON.stringify(authData));
      
      return authData;
    } catch (error) {
      console.error('Error preparing socket authentication data:', error);
      return { authenticated: false };
    }
  }
  
  /**
   * Get authentication data from storage or generate new if needed
   */
  getAuthData(): any {
    try {
      const storedDataStr = localStorage.getItem(this.AUTH_STORAGE_KEY);
      if (!storedDataStr) {
        return this.prepareAuthData();
      }
      
      const storedData = JSON.parse(storedDataStr);
      const oneHourAgo = Date.now() - (60 * 60 * 1000);
      
      // If data is older than 1 hour, refresh it
      if (!storedData.timestamp || storedData.timestamp < oneHourAgo) {
        return this.prepareAuthData();
      }
      
      return storedData;
    } catch (error) {
      console.error('Error getting socket auth data:', error);
      return this.prepareAuthData();
    }
  }
  
  /**
   * Clear authentication data on logout
   */
  clearAuthData(): void {
    localStorage.removeItem(this.AUTH_STORAGE_KEY);
  }
}
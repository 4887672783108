import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './services/interceptors/index';
import { ErrorHandlerService } from './services/error.handler.service';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogLeaveFeedback } from './dialogs/leave-feedback/leave-feedback.page';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { DialogUserProfile } from './dialogs/user-profile/user-profile.page';
import { CreateEmailSubscriptionComponent } from './dialogs/create-email-subscription/create-email-subscription.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ViewTimelineDetailsComponent } from './dialogs/view-timeline-details/view-timeline-details/view-timeline-details.component';
import { NotificationCenterComponent } from './layout/notification-center/notification-center.component';
import { DialogPharmacyNotification } from './dialogs/pharmacy-notification/pharmacy-notification.component';
import { CreatePharmacyComplianceComponent } from './dialogs/create-pharmacy-compliance/create-pharmacy-compliance.component';
import { SessionExtensionService } from './services/state/extendsession.service';
import { EnvironmentTopbarComponent } from "./shared/components/environment-topbar/environment-topbar.component";
import { CookieStorageService } from './services/cookie-storage.service';
import { SetupService } from './services/setup.service';

// hubspot chat variables
declare global {
  var HubSpotConversations: {
    widget: {
      remove: () => void;
      load: () => void;
    };
  };
  var hsConversationsSettings: {}
}

const config: SocketIoConfig = {
  url: environment.socketIo, options: {
    autoConnect: false,
  }
};

// Factory function to ensure the service is initialized
export function initializeSessionExtension(service: SessionExtensionService): () => void {
  return () => { /* Service constructor handles initialization */ };
}


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    DialogLeaveFeedback,
    DialogUserProfile,
    CreateEmailSubscriptionComponent,
    ViewTimelineDetailsComponent,
    NotificationCenterComponent,
    DialogPharmacyNotification,
    CreatePharmacyComplianceComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule,
    MatSnackBarModule,
    SharedModule,
    BrowserAnimationsModule,
    CommonModule,
    MatIconModule,
    MatDialogModule,
    AgGridModule,
    SocketIoModule.forRoot(config),
    ReactiveFormsModule,
    MatDialogModule,
    EnvironmentTopbarComponent
],
  providers: [
    ErrorHandlerService,
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      deps: [SetupService],
      multi: true,
      useFactory: ($setup: SetupService) => {
        return () => new Promise<void>(resolve => {
          $setup.init();
          setTimeout(resolve, 100);
        });
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSessionExtension,
      deps: [SessionExtensionService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      deps: [CookieStorageService],
      multi: true,
      useFactory: ($cookie: CookieStorageService) => {
        return () => new Promise<void>(resolve => {
          $cookie.loadCookiesIntoLocalStorage();
          setTimeout(resolve, 100);
        });
      },
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


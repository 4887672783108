import { Injectable } from "@angular/core";
import { BaseService } from "../dataservices/base.service";
import * as Parse from 'parse';
import { Order, User } from "@chemist2u/types-client/C2U/ParseObjects/index.client";
import C2U from "@chemist2u/types-client";
import { TAllocatedShift, TOrderFulfillmentMethod, TOrderFulfillmentMethodClickAndCollect, TOrderFulfillmentMethodOnDemand, TOrderFulfillmentMethodPostal, TOrderFulfillmentMethodPostalTemperatureControlled, TOrderFulfillmentMethodStandard, TOrderStatus, TPharmacyFulfillmentMethod, TPharmacyFulfillmentMethodClickAndCollect, TPharmacyFulfillmentMethodOnDemand, TPharmacyFulfillmentMethodPostal, TPharmacyFulfillmentMethodPostalTemperatureControlled, TPharmacyFulfillmentMethodStandard } from "@chemist2u/types-client/C2U/Interfaces";
import { ISort } from "src/app/shared/interfaces";

@Injectable({
    providedIn: 'root'
})

export class OrderHelper extends BaseService {

    constructor() {
        super('OrderHelper');
    }

    getOrdersByCustomer(customer: User): Promise<Order[]> {
        return Order.Query().equalTo("customer", customer).limit(500).find();
    }


    bookAusPostExpressDelivery(quote: any, orderID: string, pharm2Cus: boolean, transitDays: number) {
        console.log(transitDays, quote, orderID, pharm2Cus);

        return Parse.Cloud.run('bookAusPostExpressDelivery', {
            quote: quote,
            orderID: orderID,
            pharm2Cus: pharm2Cus,
            transitDays: transitDays
        })
    }
    bookAusPostOnDemandDelivery(quote: any, orderID: string, pharm2Cus: boolean, ) {
        console.log(quote, orderID, pharm2Cus);

        return Parse.Cloud.run('bookAusPostOnDemandDelivery', {
            quote: quote,
            orderID: orderID,
        })
        
    }

    markAsShippedOut(id: string): Promise<Order> {
        return Parse.Cloud.run('markOrderAsShippedOut', { id });
    }

    markAsDelivered(id: string): Promise<Order> {
        return Parse.Cloud.run('markOrderAsDelivered', { id });
    }



    markAsApprovedByCustomer(id: string): Promise<Order> {
        return Parse.Cloud.run('markOrdersAsApprovedByCustomer', { id });
    }

    markOrderAsPharmacyApproved(id: string): Promise<Order> {
        return Parse.Cloud.run('markOrderAsPharmacyApproved', { id });
    }


    markOrderAsReady(id: string): Promise<Order> {
        return Parse.Cloud.run('markOrderAsReady', { id });
    }

    markOrderAsDeliveredByPharmacy(id: string): Promise<Order> {
        return Parse.Cloud.run('markOrderAsDeliveredByPharmacy', { id });
    }

    markOrderAsDelivered(order: Order): Promise<Order> {
        order.status = 'Delivered';
        return order.save();
    }

    getAusPostDeliveryEstimate(orderID: string) {
        return Parse.Cloud.run('getAusPostDeliveryEstimate', {
            orderID: orderID
        });
    }
    getAusPostServiceabilityLookup(orderID: string) {
        return Parse.Cloud.run('getAusPostServiceabilityLookup', {
            orderID: orderID
        });
    }

    async resendPushNotification(orderId: string) {

        return Parse.Cloud.run("resendCustomerPush", { orderId: orderId });
    }


    async sendOrderEmailToPharmacy(orderId: string) {
        return Parse.Cloud.run("sendOrderEmailToPharmacy", { orderId: orderId });
    }

    async sendOrderEmailToUser(orderId: string) {
        console.log("WE PASSED IN " + orderId);
        return Parse.Cloud.run("sendOrderEmailToUserToPay", { orderId: orderId });
    }

    async sendOrderSMSToUser(userID: string, message: string, orderId: string) {
        return Parse.Cloud.run("sendSMS", { user: userID, message: message, orderID: orderId });
    }

    async sendPartnerSMSToPay(data) {
        return Parse.Cloud.run('sendPartnerSMSToPay', data);
    }

    async sendConciergeSMS(userId: string, orderId: string) {
        return Parse.Cloud.run('sendConciergeSMS', { orderId: orderId, userId: userId });
    }

    async sendPharmacyCommentEmail(data: any) {
        return Parse.Cloud.run('sendPharmacyCommentEmail', data);
    }

    resentOrderTrackingURL(userID: string, message: string, orderId: string, messageType: string) {
        return Parse.Cloud.run("sendSMS", { user: userID, message: message, orderID: orderId, messageType: messageType });
    }

    sendCancellationSMS(payload: any): Promise<any> {
        return Parse.Cloud.run('sendCancellationSMS', payload);
    }

    //sherpa delivery
    bookSherpaDelivery(orderID: string, quote: any, pharm2Cus: boolean) {
        return Parse.Cloud.run('bookSherpaDelivery', {
            orderID: orderID,
            quote: quote,
            pharm2Cus: pharm2Cus
        });
    }
    //gopeople delivery quote
    getGopeopleDeliveryQuote(orderID: string, pharm2Cus: boolean) {
        return Parse.Cloud.run('getGopeopleDeliveryQuote', {
            orderID: orderID,
            pharm2Cus: pharm2Cus,
        })
    }
    //book goPeople job based on quoteID
    bookGoPeopleDelivery(quote: any, orderID: string, pharm2Cus: boolean) {
        return Parse.Cloud.run('bookGoPeopleDelivery', {
            quote: quote,
            orderID: orderID,
            pharm2Cus: pharm2Cus
        });
    }

    //Sherpa delivery quote
    getSherpaDeliveryQuote(orderID: string, pharm2Cus: boolean) {
        return Parse.Cloud.run('getSherpaDeliveryQuote', {
            orderID: orderID,
            pharm2Cus: pharm2Cus
        });
    }

    //Uber delivery quote
    getUberDeliveryQuote(orderID: string, pharm2Cus: boolean) {
        return Parse.Cloud.run('getUberDeliveryQuote', {
            orderID: orderID,
            pharm2Cus: pharm2Cus
        });
    }

    //book uber delivery 
    bookUberDelivery(quote: any, orderID: string, pharm2Cus: boolean) {
        return Parse.Cloud.run('bookUberDelivery', {
            quote: quote,
            orderID: orderID,
            pharm2Cus: pharm2Cus
        })
    }
    //13 cabs delivery quote
    get13CabsDeliveryQuote(orderID: string, pharm2Cus: boolean) {
        return Parse.Cloud.run('get13CabsDeliveryQuote', {
            orderID: orderID,
            pharm2Cus: pharm2Cus,
        })
    }
    //book 13 cabs delivery
    book13cabsDelivery(quote: any, orderID: string, pharm2Cus: boolean) {
        console.log(quote);
        console.log(orderID);
        console.log(pharm2Cus);


        return Parse.Cloud.run('book13CabsDelivery', {
            quote: quote,
            orderID: orderID,
            pharm2Cus: pharm2Cus
        })
    }

    getDoorDashDeliveryQuote(orderID: string, pharm2Cus: boolean) {
        return Parse.Cloud.run('getDoorDashDeliveryQuote', {
            orderID: orderID,
            pharm2Cus: pharm2Cus
        })
    }

    bookDoorDashDelivery(quote: any, orderID: string, pharm2Cus: boolean) {
        return Parse.Cloud.run('bookDoorDashDelivery', {
            quote: quote,
            orderID: orderID,
            pharm2Cus: pharm2Cus
        })
    }

    //Rendr delivery quote
    getRendrDeliveryQuote(orderID: string, pharm2Cus: boolean) {
        return Parse.Cloud.run('getRendrDeliveryQuote', {
            orderID: orderID,
            pharm2Cus: pharm2Cus,
        })
    }

    bookRendrDelivery(quote: any, orderID: string, pharm2Cus: boolean) {
        return Parse.Cloud.run('bookRendrDelivery', {
            quote: quote,
            orderID: orderID,
            pharm2Cus: pharm2Cus
        })
    }



    loadPharmacyOrdersById(id: string, limit = null) {
        //@ts-ignore
        const query = new Parse.Query(Order).equalTo('pharmacy.objectId', id);
        if (limit) query.limit(limit);
        return query.findAll();
    }
    
    loadActivePharmacyOrdersById(id: string, limit = null) {
        //@ts-ignore
        const query = new Parse.Query(Order).containedIn('status', ['Ready', 'Paid', 'Pharmacy Approved']);
        if (limit) query.limit(limit);
        return query.findAll();
    }

    async sendCustomSMS(messageObject: C2U.Cloud.TSendCustomSMS): Promise<C2U.Cloud.TMessageSendSMSResult> {
        return Parse.Cloud.run('sendCustomSMS', messageObject);
    }

    async getOrderByOrderID(orderID: string) {
        return await new Parse.Query(Order).equalTo('orderID', orderID).notContainedIn('status', ['Delivered', 'Cancelled']).first();
    }


    processMissingPayment(orderId: string, reason: string): Promise<Order> {
        return Parse.Cloud.run('processMissingAmount', {
            orderId: orderId,
            reason: reason
        });
    }

    refundExcessAmount(orderId: string, reason: string): Promise<Order> {
        return Parse.Cloud.run('refundExcess', { orderId: orderId, reason: reason });

    }

    fullRefund(orderId: string, reason: string): Promise<Order> {
        return Parse.Cloud.run('fullRefundForOrder', { orderId: orderId, reason: reason });
    }

    refundCustomAmount(orderId: string, amount: number, reason: string): Promise<Order> {
        return Parse.Cloud.run('refundCustomAmount', {
            orderId: orderId,
            reason: reason,
            amount: amount
        });
    }

    async createOrderOnCustomersBehalf(id: string, orderDetails: any): Promise<Order> {
        let result = await Parse.Cloud.run('createOrderOnCustomersBehalf', {
            id: id,
            orderDetails: orderDetails
        });
        return result as Promise<Order>;
    }

    async checkOrderRefundStatus(orderId: string): Promise<Order> {
        return Parse.Cloud.run('checkRefundStatusForOrder', {
            orderId: orderId
        })
    }

    async restorePaymentsFromSquare(orderId: string): Promise<Order> {
        return Parse.Cloud.run('restorePaymentsFromSquare', {
            orderId: orderId
        })
    }

    countTodaysOrdersByStatus(status?: TOrderStatus): Promise<number> {
        let startDate = new Date();
        startDate.setHours(0, 0, 0, 0);

        const query = new Parse.Query(Order);
        query.greaterThanOrEqualTo('createdAt', new Date(startDate.toUTCString()));
        if (status) { query.equalTo('status', status); }

        return query.count();
    }
    countUndeliveredOrders(): Promise<number> {
        const query = new Parse.Query(Order);
        query.notContainedIn('status', ['Cancelled', 'Delivered']);
        return query.count();
    }

    async adminSetAllocatedShift(allocatedShift: TAllocatedShift, orderId: string, pharmacyUserId: string): Promise<any> {
        return Parse.Cloud.run('adminSetAllocatedShift', {
            allocatedShift: allocatedShift,
            orderId: orderId,
            pharmacyUserId: pharmacyUserId
        })
    }

    getSubscription(): Promise<Parse.LiveQuerySubscription> {
        let query = new Parse.Query(Order);
        query.containedIn('status', ['Ready', 'Paid', 'Pharmacy Approved']);
        return query.subscribe();
    }

    getArchivedOrders(pagination, sort?: ISort, params?, count: boolean = false): any {
        const query = new Parse.Query(Order);
        query.containedIn('status', ['Delivered', 'Cancelled']);
        query.include('customer');
        if (sort) {
            this.sort(query, sort);
        } else {
            query.descending('createdAt');
        }
        if (params) {
            this.applyParams(query, params);
        }
        if (!count) {
            query.limit(pagination.limit);
            query.skip(pagination.skip);
        }
        if (!count) {
            return query.find().then((res) => res, this.catchError);
        } else {
            return query.count().then((res) => res, this.catchError);
        }
    }

    loadArchiveCount(params: any, search: any): Promise<number> {
        let query = new Parse.Query(Order);

        if (search) {
            query.matches(search.column, search.value, 'i');

        }

        console.log(params);
        if (params) {
            if (params.status) {
                query.equalTo('status', params.status);
            }
        } else {
            query.containedIn('status', ['Delivered', 'Cancelled']);
        }
        console.log(query);
        return query.count();
    }

    loadArchive(pagination: any = { page: 0, limit: 15 }, sort: any = { direction: 'asc', column: 'createdAt' }, params: any, search: any): Promise<Order[]> {
        let query = new Parse.Query(Order);
        if (pagination) {
            query.skip(pagination.page * pagination.limit);
            query.limit(pagination.limit);
        }

        if (sort) {
            if (sort.direction == 'asc') {
                query.ascending(sort.column);
            }

            if (sort.direction == 'desc') {
                query.descending(sort.column);
            }
        }

        if (search) {
            query.matches(search.column, search.value, 'i');
        }

        if (params) {
            if (params.status) {
                query.equalTo('status', params.status);
            }
        } else {
            query.containedIn('status', ['Delivered', 'Cancelled']);
        }

        return query.find();
    }

    // loadHolding(pagination: any = { page: 0, limit: 15 }, sort: any = { direction: 'asc', column: 'createdAt' }, params: any, search: any) {
    //     let query = new Parse.Query(Order);

    //     //@ts-ignore
    //     query.equalTo('pharmacy.objectId', 'v8IU9cmIDG')
    //     query.notContainedIn('status', ['Cancelled', 'Delivered'])
    //     if (pagination) {
    //         query.skip(pagination.page * pagination.limit);
    //         query.limit(pagination.limit);
    //     }

    //     if (sort) {
    //         if (sort.direction == 'asc') {
    //             query.ascending(sort.column);
    //         }

    //         if (sort.direction == 'desc') {
    //             query.descending(sort.column);
    //         }
    //     }

    //     if (search) {
    //         query.matches(search.column, search.value, 'i');
    //     }

    //     if (params) {
    //         if (params.status) {
    //             query.equalTo('status', params.status);
    //         }
    //     }

    //     return query.find();
    // }

    // getHoldingCount(): Promise<number> {
    //     //@ts-ignore
    //     return new Parse.Query(Order).equalTo('pharmacy.objectId', 'v8IU9cmIDG').notContainedIn('status', ['Cancelled', 'Delivered']).count();
    // }


    // loadHoldingCount(params: any, search: any) {
    //     let query = new Parse.Query(Order);
    //     //@ts-ignore
    //     query.equalTo('pharmacy.objectId', 'v8IU9cmIDG')

    //     if (search) {
    //         query.matches(search.column, search.value, 'i');

    //     }

    //     console.log(params);
    //     if (params) {
    //         if (params.status) {
    //             query.equalTo('status', params.status);
    //         }
    //     } else {
    //         query.containedIn('status', ['Delivered', 'Cancelled']);
    //     }
    //     console.log(query);
    //     return query.count();
    // }




    async pharmacyLoadArchive() {
        const limit = await this.newHistoricalCount()
        let query = new Parse.Query(Order);
        query.containedIn('status', ['Delivered', 'Cancelled']);
        query.limit(limit);
        return query.find();
    }

    newHistoricalCount(): Promise<number> {
        return new Parse.Query(Order).containedIn('status', ['Delivered', 'Cancelled']).count();
    }



    newHistoricalLoad(params: any = { page: 0, limit: 15 }): Promise<Order[]> {
        const limit = 25;
        const query = new Parse.Query(Order);
        query.containedIn('status', ['Delivered', 'Cancelled']);
        query.include('customer');
        query.ascending('createdAt');

        if (params.limit) {
            query.limit(params.limit);
        } else {
            query.limit(limit);
        }

        if (params.page) {
            if (params.limit) {
                query.skip(params.page * params.limit);
            } else {
                query.skip(params.page * limit);
            }
        }

        return query.find();

    }



    //load orders for active orders table
    loadOrders(pharmacySelfManaged?: boolean): Promise<Order[]> {
        const query = new Parse.Query(Order);
        query.notContainedIn('status', ['Delivered', 'Cancelled']);
        query.include('customer');
        query.addAscending('createdAt');
        //@ts-ignore
        if (pharmacySelfManaged === true) query.equalTo('pharmacy.selfManaged', true);
        //@ts-ignore
        if (pharmacySelfManaged === false) query.equalTo('pharmacy.selfManaged', false);
        //@ts-ignore
        // query.notEqualTo('pharmacy.objectId', 'v8IU9cmIDG')
        query.limit(1500);
        return query.find();
    }



    loadAllOrdersForOffline(): Promise<Order[]> {
        const query = new Parse.Query(Order);
        query.notContainedIn('status', ['Delivered', 'Cancelled']);
        query.include('customer');
        //@ts-ignore
        // query.notEqualTo('pharmacy.objectId', 'v8IU9cmIDG')
        // query.addAscending('createdAt');
        return query.findAll();
    }



    // loadActiveOrders(pagination, sort?: ISort, params?, count: boolean = false): Promise<Order[]> | Promise<number> | never {
    loadActiveOrders(pagination, sort?: ISort, params?, count: boolean = false): any {
        const query = new Parse.Query(Order);
        query.notContainedIn('status', ['Delivered', 'Cancelled']);
        if (!count) {
            query.limit(pagination.limit);
            query.skip(pagination.skip);
        }
        if (params) {
            this.applyParams(query, params);
        }
        query.include('customer');
        if (sort) {
            this.sort(query, sort);
        } else {
            query.descending('createdAt');
        }

        //@ts-ignore
        // query.notEqualTo('pharmacy.objectId', 'v8IU9cmIDG');
        if (!count) {
            return query.find().then((res) => res, this.catchError);
        } else {
            return query.count().then((res) => res, this.catchError);
        }
    }




    load(pagination, sort?: ISort, params?, count: boolean = false): any {
        const query = new Parse.Query(Order);
        query.notContainedIn('status', ['Delivered', 'Cancelled']);
        if (!count) {
            query.limit(pagination.limit);
            query.skip(pagination.skip);
        }
        if (params) {
            this.applyParams(query, params);
        }
        query.include('customer');
        if (sort) {
            this.sort(query, sort);
        } else {
            query.descending('createdAt');
        }
        if (!count) {
            return query.find().then((res) => res, this.catchError);
        } else {
            return query.count().then((res) => res, this.catchError);
        }
    }


    loadActiveDeliveryOrders(): Promise<Order[]> {
        const query = new Parse.Query(Order);
        query.containedIn('status', ['Ready', 'Shipped Out']);
        query.ascending('status');
        return query.find();
    }



    async loadNewOrders(): Promise<any> {
        const query = new Parse.Query(Order);
        query.include('customer');
        query.descending('updatedAt');
        query.doesNotExist('deletedAt');
        query.equalTo('status', 'Paid');
        return query.find();
    }

    loadInProgressOrders(): Promise<Order[]> {
        const query = new Parse.Query(Order);
        query.include('customer');
        query.descending('updatedAt');
        query.doesNotExist('deletedAt');
        query.equalTo('status', 'Pharmacy Approved');
        return query.find();
    }

    loadReadyOrders(): Promise<Order[]> {
        const query = new Parse.Query(Order);
        query.include('customer');
        query.descending('updatedAt');
        query.doesNotExist('deletedAt');
        query.equalTo('status', 'Ready');
        return query.find();
    }

    loadShippedoutOrders(): Promise<Order[]> {
        const query = new Parse.Query(Order);
        query.include('customer');
        query.descending('updatedAt');
        query.doesNotExist('deletedAt');
        query.equalTo('status', 'Shipped Out');
        return query.find();
    }



    // Need to work in getting orders by geo bounding box instead of all orders.
    getAllOrders(): Promise<Order[]> {
        const query = new Parse.Query(Order);
        query.greaterThanOrEqualTo('createdAt', new Date(new Date().setDate(new Date().getDate() - 90)));
        query.equalTo('status', 'Delivered');
        query.limit(15000000);

        return query.find();
    }


    async all(params): Promise<Order[]> {
        const query = new Parse.Query(Order);

        const limit = 10;
        const skipAmount = limit * params.page - limit;
        console.log('The skip amount is' + skipAmount);

        console.log('The parameters are');
        console.log(params);
        if (params && params.page) {
            console.log('Do we have parameters');
            query.limit(limit);
            query.skip((params.page * limit) - limit);
        }
        query.containedIn('status', ['Delivered', 'Cancelled']);
        query.include('customer');
        query.descending('createdAt');
        query.doesNotExist('deletedAt');
        const orders = await query.find();
        console.log(orders.length);
        console.log(orders);
        return orders;
    }

    private isClickAndCollect(method: TPharmacyFulfillmentMethod): method is TPharmacyFulfillmentMethodClickAndCollect {
        return (method as TPharmacyFulfillmentMethodClickAndCollect).method === 'clickAndCollect';
    }

    private isOnDemand(method: TPharmacyFulfillmentMethod): method is TPharmacyFulfillmentMethodOnDemand {
        return (method as TPharmacyFulfillmentMethodOnDemand).method === 'OnDemand';
    }

    private isStandard(method: TPharmacyFulfillmentMethod): method is TPharmacyFulfillmentMethodStandard {
        return (method as TPharmacyFulfillmentMethodStandard).method === 'Standard';
    }

    private isPostal(method: TPharmacyFulfillmentMethod): method is TPharmacyFulfillmentMethodPostal {
        return (method as TPharmacyFulfillmentMethodPostal).method === 'Postal';
    }

    private isTemperatureControl(method: TPharmacyFulfillmentMethod): method is TPharmacyFulfillmentMethodPostalTemperatureControlled {
        return (method as TPharmacyFulfillmentMethodPostalTemperatureControlled).method === 'PostalTemperatureControlled'
    }

    // Main mapping function
    public mapToOrderFulfillmentMethod(selectedMethod: TPharmacyFulfillmentMethod): TOrderFulfillmentMethod {
        if (this.isClickAndCollect(selectedMethod)) {
            return {
                selectedMethod,
                clickAndCollectCutoffMinutes: null, // Example value
                expectedPickupDate: new Date(), // Example or default value
                clickAndCollectPin: '', // Optional
                pinRequested: false,
                pinRequestTimestamp: undefined
            } as TOrderFulfillmentMethodClickAndCollect;
        } else if (this.isOnDemand(selectedMethod)) {
            return {
                selectedMethod,
                allocatedShift: undefined // Example placeholder; replace with actual TAllocatedShift data if available
            } as TOrderFulfillmentMethodOnDemand;
        } else if (this.isStandard(selectedMethod)) {
            return {
                selectedMethod,
                expectedDeliveryDate: new Date() // Example or default value
            } as TOrderFulfillmentMethodStandard;
        } else if (this.isPostal(selectedMethod)) {
            return {
                selectedMethod,
                trackingNumber: '', // Example value
                trackingURL: '', // Example URL
                shippedOutTimestamp: new Date() // Example or default value
            } as TOrderFulfillmentMethodPostal;
        } else if (this.isTemperatureControl(selectedMethod)) {
            return {
                selectedMethod,
            } as TOrderFulfillmentMethodPostalTemperatureControlled;
        }
        else {
            throw new Error("Unknown fulfillment method selected");
        }
    }


}